import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    FormLabel
} from '@mui/material';

const OvertimeJobForm = () => {
    const [employees, setEmployees] = useState([]);
    const [formData, setFormData] = useState({
        performed_by: '',
        requested_by: '',
        location: '',
        description: '',
        start: '',
        end: '',
        planned: 0,    // Default to 0 (not planned)
        emergency: 0,   // Default to 0 (not emergency)
        notes: ''
    });

    useEffect(() => {
        const fetchEmployees = async () => {
            const response = await axios.get('https://boftel.mekre.ee/api/employees');
            setEmployees(response.data);
        };
        fetchEmployees();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTypeChange = (type) => {
        setFormData(prev => ({
            ...prev,
            planned: type === 'planned' ? 1 : 0,
            emergency: type === 'emergency' ? 1 : 0
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post('https://boftel.mekre.ee/api/overtime_jobs', formData);
        alert('Job added successfully!');
        setFormData({
            performed_by: '',
            requested_by: '',
            location: '',
            description: '',
            start: '',
            end: '',
            planned: 0,    // Resetting to 0
            emergency: 0,   // Resetting to 0
            notes: ''
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="performed_by-label" required>Tööde teostaja</InputLabel>
                <Select
                    labelId="performed_by-label"
                    label="Tööde teostaja"
                    name="performed_by"
                    value={formData.performed_by}
                    onChange={handleChange}
                >
                    {employees.map(emp => (
                        <MenuItem key={emp._id} value={emp._id}>{emp.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                name="requested_by"
                label="Tööde totleja"
                fullWidth
                margin="normal"
                value={formData.requested_by}
                onChange={handleChange}
                required
            />
            <TextField
                name="location"
                label="Tööde teostamise koht"
                fullWidth
                margin="normal"
                value={formData.location}
                onChange={handleChange}
                required
            />
            <TextField
                name="description"
                label="Ületöö kirjeldus"
                fullWidth
                margin="normal"
                multiline
                rows={3}
                value={formData.description}
                onChange={handleChange}
                required
            />
            <TextField
                name="start"
                label="Tööde algus"
                type="datetime-local"
                fullWidth
                margin="normal"
                value={formData.start}
                onChange={handleChange}
                slotProps={{
                    inputLabel: () => ({
                        shrink: true
                    })
                }}
                required
            />
            <TextField
                name="end"
                label="Tööde lõpp"
                type="datetime-local"
                fullWidth
                margin="normal"
                value={formData.end}
                onChange={handleChange}
                slotProps={{
                    inputLabel: () => ({
                        shrink: true
                    })
                }}
                required
            />
            <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend" required>Tööde tüüp</FormLabel>
                <RadioGroup
                    row
                    name="type"
                    onChange={(e) => handleTypeChange(e.target.value)}
                >
                    <FormControlLabel value="planned" control={<Radio />} label="Plaaniline" />
                    <FormControlLabel value="emergency" control={<Radio />} label="Avarii" />
                </RadioGroup>
            </FormControl>
            <TextField
                name="notes"
                label="Märkused"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={formData.notes}
                onChange={handleChange}
                required
            />
            <Button type="submit" variant="contained" color="primary">Lisa töö</Button>
        </form>
    );
};

export default OvertimeJobForm;
