// src/App.js
import React from 'react';
import OvertimeJobForm from './OvertimeJobForm';
import { Container, Typography } from '@mui/material';

const App = () => {
    return (
        <Container maxWidth="xs">
            <Typography variant="h4" gutterBottom>
                Ületöö raport
            </Typography>
            <OvertimeJobForm />
        </Container>
    );
};

export default App;